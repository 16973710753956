// ..\student-portal-backend\LU.STP.Common\Models\Assignment.cs
export interface Assignment {
  noteTakerLuEduPersonPrimaryId: string;
  studentLuEduPersonPrimaryId: string;
  courseCode: string;
  term: string;
}

// ..\student-portal-backend\LU.STP.Common\Models\Coordinator.cs
export interface Coordinator extends CoordinatorBase {
  email: string;
  functionId: number;
}

// ..\student-portal-backend\LU.STP.Common\Models\CoordinatorBase.cs
export interface CoordinatorBase {
  id: number;
  name: string;
}

// ..\student-portal-backend\LU.STP.Common\Models\Course.cs
export interface Course {
  courseUid: string;
  courseCode: string;
  titleSv: string;
  titleEn: string;
  courseStartDate: string;
  courseEndDate: string;
  term: string;
  occasionCode: string;
  facultySv: string;
  facultyEn: string;
  institutionSv: string;
  institutionEn: string;
  studyPoints: number;
  event: string;
}

// ..\student-portal-backend\LU.STP.Common\Models\CourseOccurrence.cs
export interface CourseOccurrence extends CourseOccurrenceBase {
  students?: Student[];
  noteTakers?: NoteTaker[];
  inactiveStudents?: Student[];
  inactiveNoteTakers?: NoteTaker[];
  proposedNoteTakers?: NoteTakerProposedCourse[];
  noteTakerOffers?: NoteTakerOffer[];
  noteTakerUploadSummaries?: NoteTakerUploadSummary[];
}

// ..\student-portal-backend\LU.STP.Common\Models\CourseOccurrenceBase.cs
export interface CourseOccurrenceBase extends Course, IHasIntId {
  id: number;
  coordinatorName?: string;
  coordinatorId?: number;
  occurrence: string;
  studentCount: number;
  noteTakerCount: number;
  proposedNoteTakersCount: number;
  status: CourseOccurrenceStatus;
  latestNoteTakerOffer: string;
  studyPoints: number;
}

// ..\student-portal-backend\LU.STP.Common\Models\Faculty.cs
export interface Faculty {
  id: number;
  nameSv: string;
  nameEn: string;
  isCampus: boolean;
}

// ..\student-portal-backend\LU.STP.Common\Models\NoteTaker.cs
export interface NoteTaker
  extends NoteTakerBase,
    INoteTaker,
    ISupportCourseParticipant {
  students?: Student[];
  proposedCourses?: ProposedCourse[];
  supportCourses?: PersonCourse[];
  supportCourseOccurrences?: SupportCourseOccurrence[];
  isAnActiveStudentInNeed: boolean;
}

// ..\student-portal-backend\LU.STP.Common\Models\NoteTakerBase.cs
export interface NoteTakerBase extends Person {
  status: NoteTakerStatus;
}

// ..\student-portal-backend\LU.STP.Common\Models\NoteTakerNotes.cs
export interface NoteTakerNotes {
  courseOccurrenceId: number;
  courseUid: string;
  courseCode: string;
  courseTerm: string;
  courseTitleEn: string;
  courseTitleSv: string;
  noteTakerId: number;
  noteTakerFirstName: string;
  noteTakerLastName: string;
  date: string;
  time: string;
  file: UploadFile;
}

// ..\student-portal-backend\LU.STP.Common\Models\NoteTakerOffer.cs
export interface NoteTakerOffer {
  id: number;
  courseOccurrenceId: number;
  noteTakerId: number;
  sent: string;
  confirmed?: string;
  canceled?: string;
  courseOccurrence: CourseOccurrence;
  noteTaker: NoteTaker;
}

// ..\student-portal-backend\LU.STP.Common\Models\NoteTakerParameters.cs
export interface NoteTakerParameters extends QueryStringParameters {
  status: NoteTakerStatus[];
}

// ..\student-portal-backend\LU.STP.Common\Models\NoteTakerProposedCourse.cs
export interface NoteTakerProposedCourse {
  noteTakerId: number;
  noteTaker: NoteTaker;
  courseUid: string;
  inserted?: string;
  deleted?: string;
}

// ..\student-portal-backend\LU.STP.Common\Models\NoteTakerStatusCount.cs
export interface NoteTakerStatusCount {
  status: string;
  count: number;
}

// ..\student-portal-backend\LU.STP.Common\Models\NoteTakerUploadSummary.cs
export interface NoteTakerUploadSummary {
  noteTakerId: number;
  noteTakerName: string;
  totalUploads: number;
  lastUpload?: string;
}

// ..\student-portal-backend\LU.STP.Common\Models\OverviewNoteTaker.cs
export interface OverviewNoteTaker extends OverviewPerson, INoteTaker {
  status: NoteTakerStatus;
}

// ..\student-portal-backend\LU.STP.Common\Models\OverviewPerson.cs
export interface OverviewPerson extends IPerson, IHasIntId {
  id: number;
  personalId: string;
  luEduPersonPrimaryId: string;
  firstName: string;
  lastName: string;
  email: string;
}

// ..\student-portal-backend\LU.STP.Common\Models\OverviewStudent.cs
export interface OverviewStudent extends OverviewPerson, IStudent {
  coordinatorName?: string;
  coordinatorId?: number;
  grantedSupportDate?: string;
}

// ..\student-portal-backend\LU.STP.Common\Models\PagedList.cs
export interface PagedList<T> {
  items: T[];
  currentPage: number;
  totalPages: number;
  pageSize: number;
  totalCount: number;
  hasPrevious: boolean;
  hasNext: boolean;
  sortedIds: number[];
}

// ..\student-portal-backend\LU.STP.Common\Models\PageSection.cs
export interface PageSection {
  id: number;
  pageTypeId: number;
  titleSv: string;
  contentSv: string;
  linkTextSv: string;
  menuTitleSv: string;
  titleEn: string;
  contentEn: string;
  linkTextEn: string;
  menuTitleEn: string;
  routerLinkUrl?: string;
  externalLinkUrl?: string;
  modified: string;
  modifiedBy: string;
  sortOrder: number;
}

// ..\student-portal-backend\LU.STP.Common\Models\PageType.cs
export interface PageType {
  id: number;
  name: string;
  modified: string;
  modifiedBy: string;
  pageSections: PageSection[];
}

// ..\student-portal-backend\LU.STP.Common\Models\Person.cs
export interface Person extends PersonBase, IHasIntId {
  id: number;
  luEduPersonPrimaryId: string;
  personalId: string;
  telephoneNumber: string;
  address: string;
  postcode: string;
  city: string;
  courses: Course[];
}

// ..\student-portal-backend\LU.STP.Common\Models\PersonBase.cs
export interface PersonBase {
  firstName: string;
  lastName: string;
  email: string;
}

// ..\student-portal-backend\LU.STP.Common\Models\PersonCourse.cs
export interface PersonCourse {
  courseUid: string;
  courseOccurrenceId: number;
  started?: string;
  ended?: string;
}

// ..\student-portal-backend\LU.STP.Common\Models\PersonResponse.cs
export interface PersonResponse {
  studentExists: boolean;
  student: Student;
  isActiveNoteTaker: boolean;
  noteTakerId: number;
}

// ..\student-portal-backend\LU.STP.Common\Models\ProposedCourse.cs
export interface ProposedCourse extends Course {
  id: number;
  courseOccurrenceId: number;
}

// ..\student-portal-backend\LU.STP.Common\Models\QueryStringParameters.cs
export interface QueryStringParameters {
  search?: string;
  sortBy: string;
  sortOrder: SortOrder;
  pageNumber: number;
  pageSize: number;
  coordinatorId?: number;
}

// ..\student-portal-backend\LU.STP.Common\Models\Student.cs
export interface Student extends Person, ISupportCourseParticipant, IStudent {
  coordinatorId?: number;
  noteTakers?: NoteTaker[];
  coordinator?: Coordinator;
  coordinatorName?: string;
  grantedSupportDate?: string;
  ladokCourseCount: number;
  supportCourses?: PersonCourse[];
  supportCourseOccurrences?: SupportCourseOccurrence[];
}

// ..\student-portal-backend\LU.STP.Common\Models\StudentCourse.cs
export interface StudentCourse {
  id: number;
  studentId?: number;
  courseUid: string;
  inserted?: string;
}

// ..\student-portal-backend\LU.STP.Common\Models\StudentParameters.cs
export type StudentParameters = QueryStringParameters;

// ..\student-portal-backend\LU.STP.Common\Models\StudyTermInfo.cs
export interface StudyTermInfo {
  previousTerm: string;
  thisTerm: string;
  nextTerm: string;
  startOfPreviousTerm: string;
  startOfThisTerm: string;
  startOfNextTerm: string;
}

// ..\student-portal-backend\LU.STP.Common\Models\SupportCourseOccurrence.cs
export interface SupportCourseOccurrence {
  courseUid: string;
  courseOccurrenceId: number;
  titleSv: string;
  titleEn: string;
  status: CourseOccurrenceStatus;
  occurrence: string;
  courseOccurrenceStart?: string;
  offerDate?: string;
  offerConfirmedDate?: string;
  assignmentStart?: string;
  assignmentEnd?: string;
  coordinatorName: string;
  deletedByAdmin?: boolean;
}

// ..\student-portal-backend\LU.STP.Common\Models\UploadFile.cs
export interface UploadFile {
  name: string;
  extension: string;
  size: number;
  binaryData?: string;
}

// ..\student-portal-backend\LU.STP.Common\Interfaces\IHasIntId.cs
export interface IHasIntId {
  id: number;
}

// ..\student-portal-backend\LU.STP.Common\Interfaces\INoteTaker.cs
export interface INoteTaker extends IPerson {
  status: NoteTakerStatus;
}

// ..\student-portal-backend\LU.STP.Common\Interfaces\IPerson.cs
export interface IPerson {
  id: number;
  personalId: string;
  luEduPersonPrimaryId: string;
  firstName: string;
  lastName: string;
  email: string;
}

// ..\student-portal-backend\LU.STP.Common\Interfaces\IStudent.cs
export interface IStudent extends IPerson {
  coordinatorName?: string;
  grantedSupportDate?: string;
}

// ..\student-portal-backend\LU.STP.Common\Interfaces\ISupportCourseParticipant.cs
export interface ISupportCourseParticipant {
  supportCourses?: PersonCourse[];
}

// ..\student-portal-backend\LU.STP.Common\Enums\CourseOccurrenceStatus.cs
export enum CourseOccurrenceStatus {
  Grey = "grey",
  Yellow = "yellow",
  Green = "green",
  Orange = "orange",
  Red = "red",
}

// ..\student-portal-backend\LU.STP.Common\Enums\FeeDataExportType.cs
export enum FeeDataExportType {
  Basis = "basis",
  Accounting = "accounting",
}

// ..\student-portal-backend\LU.STP.Common\Enums\Locale.cs
export enum Locale {
  Sv = "sv",
  En = "en",
}

// ..\student-portal-backend\LU.STP.Common\Enums\NoteTakerStatus.cs
export enum NoteTakerStatus {
  Incoming = "incoming",
  Active = "active",
  Inactive = "inactive",
  Completed = "completed",
}

// ..\student-portal-backend\LU.STP.Common\Enums\SortOrder.cs
export enum SortOrder {
  Asc = "asc",
  Desc = "desc",
}

// ..\student-portal-backend\LU.STP.Common\Models\WebForm\WF_NationalIdentityNumberChange.cs
export interface WF_NationalIdentityNumberChange {
  luEduPersonPrimaryId: string;
  newNationalIdentityNumber: string;
}

// ..\student-portal-backend\LU.STP.Common\Models\TuitionFees\RegisterCardRequest.cs
export interface RegisterCardRequest {
  invoiceNo: string;
  customerEmail: string;
}

// ..\student-portal-backend\LU.STP.Common\Models\TuitionFees\RegisterCardResponse.cs
export interface RegisterCardResponse {
  resourceUrl: string;
  error: string;
}

// ..\student-portal-backend\LU.STP.Common\Models\TuitionFees\TfPerson.cs
export interface TfPerson {
  luEduPersonPrimaryId: string;
  personalId: string;
  firstName: string;
  lastName: string;
  email: string;
  displayName: string;
  eduPersonAffiliation: string;
  eduPersonPrimaryAffiliation: string;
}

// ..\student-portal-backend\LU.STP.Common\Models\TuitionFees\Invoice\Invoice.cs
export interface Invoice {
  customerTrxId: number;
  kund: string;
  kundNr: string;
  nationalIdentification: string;
  fakNr: string;
  totalAmount: string;
  amountDue: string;
  transferDate: string;
  dueDate: string;
  payFlag: string;
  description: string;
  pending: boolean;
  lines: InvoiceLine[];
}

// ..\student-portal-backend\LU.STP.Common\Models\TuitionFees\Invoice\InvoiceLine.cs
export interface InvoiceLine {
  description: string;
  amount: string;
}

// ..\student-portal-backend\LU.STP.Common\Models\TuitionFees\Nets\NetsTerminalResponse.cs
export interface NetsTerminalResponse {
  invoiceNumber: string;
  transactionId: string;
  responseCode: string;
}
